function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProgressBar = ({
  className,
  progress = [{
    percentage: 0,
    className
  }]
}) => { 
  let totalPercentageComplete = 0;
  progress.forEach((phase) => {
    totalPercentageComplete += phase.percentage;
  });

  return (
    <div 
      role="progressbar"
      aria-valuenow={ totalPercentageComplete } 
      aria-valuemin="0" 
      aria-valuemax="100"
      className={classNames(
        className ? className : '',
        "overflow-hidden bg-gray-200 dark:bg-slate-800 flex"
      )}>
      
      {progress.map((phase, phaseIdx) => (
        <div 
          key={phaseIdx}
          style={{ width: phase.percentage + '%', transition: 'width 700ms' }}
          className={phase.className ? phase.className : "h-1 bg-green-500"}
        />
      ))}

    </div>
  );
}
 
export default ProgressBar;