import { useState } from 'react';

import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';

const Premium = () => { 
  const [isSlideOverOpen, setOpenSlideOver] = useState(false);

  const navigateToStripe = () => {
    const win = window.open('https://buy.stripe.com/6oE29BeFkamw5Yk9AA', '_blank');
    if (win) {
      win.focus();
    }
  }

  const handleComplete = () => {
    setOpenSlideOver(false);
  }

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl inline-flex">
            Go Premium  
            <RocketLaunchIcon className="ml-2 h-10 sm:h-14" aria-hidden="true" />
          </h1>
          
          <p className="mt-6 inline-block text-lg leading-8 text-gray-600 dark:text-white">
            Access our full feature set without advertisements!

            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 dark:text-white">
              <li className="flex gap-x-3">
                <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                Advanced wellness reports
              </li>
            </ul>
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a href="#" 
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => navigateToStripe()}>
              Get Premium
            </a>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
      </div>
    </div>
  )
}
 
export default Premium;