const SkeletonLoader = ({children}) => {

  return (
    <div className="w-full">
      <div className="animate-pulse">
        { children }
      </div>
    </div>
  );
}
 
export default SkeletonLoader;