import { useStoreState } from 'easy-peasy';

const ActivityWidget = () => {
  const activities = useStoreState((state) => state.wellness.activity.data);

  return (
    <section>

      { activities?.length > 0 ?

        <table className="min-w-full divide-y divide-gray-200 dark:divide-slate-800">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-black dark:text-white"
              >
                Date/Time
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-xs font-semibold text-black dark:text-white">
                Type
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-xs font-semibold text-black dark:text-white">
                Value
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-slate-800 bg-transparent">
            {activities?.map((activity, activityIdx) => (
              <tr key={activityIdx}>
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs text-gray-500 dark:text-slate-400">
                  {activity.createdAt}
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-xs text-gray-500 dark:text-slate-400">Weigh-in</td>
                <td className="whitespace-nowrap px-3 py-2 text-xs text-gray-500 dark:text-slate-400">{activity.details.weightPounds}</td>
              </tr>
            ))}
          </tbody>
        </table>

      :

        <div className="text-center">
          No activity recorded within this timeframe
        </div>
      
      }

    </section>
  )
}

export default ActivityWidget;