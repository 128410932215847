import SkeletonLoader from "../../ui/SkeletonLoader/SkeletonLoader";

const LowCarbWidget = () => { 

  return (
    <section>  
      <ol className="divide-y divide-gray-200 text-sm leading-6 text-gray-500 dark:text-slate-400 dark:divide-slate-800">
        <li className="py-4 sm:flex">
          <SkeletonLoader>
            <div className="grid grid-cols-4 gap-4">
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-2"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
            </div>
          </SkeletonLoader>
        </li>
        <li className="py-4 sm:flex">
          <SkeletonLoader>
            <div className="grid grid-cols-4 gap-4">
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-2"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
            </div>
          </SkeletonLoader>
        </li>
        <li className="py-4 sm:flex">
          <SkeletonLoader>
            <div className="grid grid-cols-4 gap-4">
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-2"></div>
              <div className="h-5 bg-gray-200 dark:bg-slate-800 rounded col-span-1"></div>
            </div>
          </SkeletonLoader>
        </li>
      </ol>
    </section>
  )
}

export default LowCarbWidget;