import { useEffect } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AdsComponent = (props) => {
    const {
        dataAdSlot,
        adFormat = 'auto',
        className
    } = props;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) { }
    },[]);

    return (
        <div className={classNames(
            className ? className : '',
            'bg-gray-200/70 dark:bg-slate-800 rounded-md flex',
          )}>

            <div className="m-auto font-extralight text-sm tracking-wide text-gray-400 dark:slate-700 py-5">
                Advertisement
            </div>
            
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-3457771507577009"
                data-ad-slot={dataAdSlot}
                data-ad-format={adFormat}
                data-full-width-responsive="true">
            </ins>

        </div>
    );
};

export default AdsComponent;