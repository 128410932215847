import { useState } from 'react';
import { useController } from 'react-hook-form';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CheckboxButton = ({
  name, //required
  label, //required
  control, // required,
  className = ''
}) => {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control
  });

  const [checked, setChecked] = useState(false);

  return (     
    <div id={'checkbox-' + field.name} onClick={() => setChecked(!checked)} 
      className={classNames(
        checked  ? 'border-green-500 border-2' : 'border-gray-300 dark:border-slate-800 border',
        className ? className : '',
        'relative block cursor-pointer rounded-md bg-white dark:bg-slate-800 dark:text-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
    )}>
      <label htmlFor={'checkbox-input-' + field.name} className="cursor-pointer">
        {label}
      </label>
      <div>
        <input
          id={'checkbox-input-' + field.name}
          name={field.name}
          value={field.value ?? false}
          type="checkbox"
          control={control}
          className="hidden"
        />
      </div>
    </div>
  );
}
 
export default CheckboxButton;