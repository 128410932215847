import { IOption } from "../interfaces/option";

export const YES_NO_OPTIONS: IOption[] = [
  {
    label: "Yes",
    value: "Yes"
  },
  {
    label: "No",
    value: "No"
  }
];

export const GENDER_OPTIONS: IOption[] = [
  {
    label: "Female",
    value: "Female"
  },
  {
    label: "Male",
    value: "Male"
  }
];

export const AGE_OPTIONS: IOption[] = getAgeOptions();

function getAgeOptions(): IOption[] {
  let ageOptions: IOption[] = [];
  for (let i = 0; i <= 100; i++) {
    ageOptions.push({
      label: i.toString(),
      value: i
    });
  }
  return ageOptions;
}

export const HEIGHT_OPTIONS: IOption[] = getHeightOptions();

function getHeightOptions(): IOption[] {
  let heightOptions: IOption[] = [];
  for (let feet = 1; feet <= 8; feet++) {
    for (let inches = 0; inches < 12; inches++) {
      heightOptions.push({
        label: feet + " feet, " + inches + " inches",
        value: feet * 12 + inches
      });
    }
  }
  return heightOptions;
}

export const WEIGHT_OPTIONS: IOption[] = getWeightOptions();

function getWeightOptions(): IOption[] {
  let ageOptions: IOption[] = [];
  for (let i = 0; i <= 300; i++) {
    ageOptions.push({
      label: i.toString() + ' pounds',
      value: i
    });
  }
  return ageOptions;
}

export const ACTIVITY_LEVEL_OPTIONS: IOption[] = [
  {
    label: "Sedentary",
    value: "Sedentary"
  },
  {
    label: "Low",
    value: "Low"
  },
  {
    label: "Somewhat",
    value: "Somewhat"
  },
  {
    label: "Active",
    value: "Active"
  },
  {
    label: "Highly",
    value: "Highly"
  }
];

export const TIMEZONE_OPTIONS: IOption[] = [
  {
    label: "Alaska Daylight Time	Anchorage",
    value: "AKDT"
  },
  {
    label: "Hawaii Standard Time	Honolulu",
    value: "HST"
  },
  {
    label: "Hawaii-Aleutian Daylight Time	Adak",
    value: "HDT"
  },
  {
    label: "Pacific Daylight Time	Los Angeles",
    value: "PDT"
  },
  {
    label: "Mountain Standard Time",
    value: "MST"
  },
  {
    label: "Mountain Daylight Time",
    value: "MDT"
  },
  {
    label: "Central Daylight Time",
    value: "CDT"
  },
  {
    label: "Eastern Daylight Time",
    value: "EDT"
  }
];

export const DAYS: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getTodaysName = () => {
  var d = new Date();
 return DAYS[d.getDay()];
}