import { StoreProvider } from 'easy-peasy';
import store from './store';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import Layout from './pages/Layout/Layout';

const App = () => {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
