import { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';

import Splash from "../../components/ui/Splash/Splash";
import Modal from '../../components/ui/Modal/Modal';
import Button from '../../components/ui/Form/Button/Button';
import Notification from '../../components/ui/Notification/Notification';
import Loading from '../../components/ui/Loading/Loading';

import WellnessDashboard from "../../pages/Wellness/WellnessDashboard";
import WellnessFormBudget from "../../components/form/Wellness/WellnessForm_Budget";
import WellnessFormGoals from "../../components/form/Wellness/WellnessForm_Goals";
import WellnessFormGoalsWeightStart from '../../components/form/Wellness/WellnessForm_Goals_Weight_Start';
import WellnessFormGoalsWeight from '../../components/form/Wellness/WellnessForm_Goals_Weight';
import WellnessFormGoalsWeightEnd from '../../components/form/Wellness/WellnessForm_Goals_Weight_End';
import WellnessFormGoalsMuscle from '../../components/form/Wellness/WellnessForm_Goals_Muscle';
import WellnessFormHabits from "../../components/form/Wellness/WellnessForm_Habits";
import WellnessFormHistory from "../../components/form/Wellness/WellnessForm_History";
import WellnessFormPersonalInfo from "../../components/form/Wellness/WellnessForm_Personal_Info";

import Premium from "../../pages/Premium/Premium";

import { askChatGpt } from "../../services/ChatGptService";
import WellnessFormGoalsMuscleEnd from '../../components/form/Wellness/WellnessForm_Goals_Muscle_End';
import WellnessFormActivity from '../../components/form/Wellness/WellnessForm_Activity';
import WellnessFormGoalsWeightWeekly from '../../components/form/Wellness/WellnessForm_Goals_Weight_Weekly';
import WellnessFormAd from '../../components/form/Wellness/WellnessForm_Ad';
import ProgressBar from '../../components/ui/ProgressBar/ProgressBar';

const Wellness = () => { 
  const wellnessPlan = useStoreState((state) => state.wellness.plan.data);
  const saveWellnessPlan = useStoreActions((actions) => actions.wellness.plan.saveWellnessPlan);
  const setWellnessPlan = useStoreActions((actions) => actions.wellness.plan.setWellnessPlan);

  const [isSlideOverOpen, setOpenSlideOver] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);

  const [notification, setNotification] = useState(null);

  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setActiveStep(activeStep + 1 < steps?.length ? activeStep + 1 : activeStep);
  } 

  const previousStep = () => {
    setActiveStep(activeStep - 1 >= 0 ? activeStep - 1 : activeStep);
  }

  const onClose = () => {
    setOpenSlideOver(false);
    setActiveStep(0);
  }

  const onPrmeiumClose = () => {
    setIsPremiumModalOpen(false);
  }

  const { 
    reset, 
    formState, 
    handleSubmit, 
    control, 
    setError, 
    formState: { errors, isValid, isDirty, isSubmitting } 
  } = useForm({
    defaultValues: {
      personalInfo: {
        gender: null,
        age: null,
        heightFeet: null,
        heightInches: null,
        weightPounds: null
      },
      goals: {
        weightPounds: null
      },
    },
   
    values: {
      ...wellnessPlan?.settings
    }
  });

  const handleComplete = () => {
    handleSubmit(onSubmit, onError)();
    setOpenSlideOver(false);
  }

  const onSubmit = async (data, e) => {
    setLoading(true);
    
    const planDetails= {
      calorieAllotmentPerDay: null,
      days: [{
        day: "",
        breakfast: {
          description: "",
          recipe: "",
          estimatedCalorieIntake: 0,
          foodOrderDetails: [{
            ingredient: "",
            quantity: ""
          }]
        },
        lunch: {
          description: "",
          recipe: "",
          estimatedCalorieIntake: 0,
          foodOrderDetails: [{
            ingredient: "",
            quantity: ""
          }]
        },
        dinner: {
          description: "",
          recipe: "",
          estimatedCalorieIntake: 0,
          foodOrderDetails: [{
            ingredient: "",
            quantity: ""
          }]
        },
        snacks: {
          description: "",
          recipe: "",
          estimatedCalorieIntake: 0,
          foodOrderDetails: [{
            ingredient: "",
            quantity: ""
          }]
        },
        workouts: [{
          title: "",
          description: "",
          estimatedCalorieBurn: 0,
        }]
      }],
      tips: [],
      notes: "",
    };

    let prompt = "Generate a detailed wellness plan using this data: ";
    prompt += JSON.stringify(data);
    prompt += " and ensure there is a plan for each of the 7 days of the week and only respond ONLY using the following JSON format: ";
    prompt += JSON.stringify(planDetails);

    const rawResponse = await askChatGpt('user', prompt);
    console.log('rawResponse', rawResponse);

    const jsonResponse = JSON.parse(rawResponse?.message?.content?.replace(/```json\n?|```/g, ''));

    const mealPlan = {
      settings: data,
      plan: jsonResponse
    };

    await saveWellnessPlan({
      ...mealPlan,
    });

    await setWellnessPlan(mealPlan);

    setLoading(false);

    setNotification({
        display: true,
        header: 'Wellness Plan Updated!',
        content: 'Your wellness plan has been saved.'
    });
}

  const onError = (errors, e) => console.log(errors, e);

  const steps = [
    <WellnessFormPersonalInfo control={control}  />
    /*
    <WellnessFormGoals control={control} />,
    <WellnessFormGoalsWeightStart control={control} />,
    <WellnessFormGoalsWeight control={control} />,
    <WellnessFormGoalsWeightEnd control={control} />,
    <WellnessFormAd />,
    <WellnessFormGoalsMuscle control={control} />,
    <WellnessFormGoalsMuscleEnd control={control} />,
    <WellnessFormActivity control={control} />,
    <WellnessFormGoalsWeightWeekly control={control} />
    */
    /*
    <WellnessFormHistory control={control} />,
    <WellnessFormHabits control={control} />,
    <WellnessFormBudget control={control} />
    */
  ]

  return (
    <>
      {loading ? 
        <Loading label="Gathering Wellness Plan" className="h-screen" />
      :

        wellnessPlan ?
          <WellnessDashboard wellnessPlan={wellnessPlan} setOpenSlideOver={setOpenSlideOver} />
        :
          <div>

            <div className="p-2 md:p-5">
              <Splash 
                titleLine1="Welcome to"
                titleLine2="HelloLife Wellness"
                description="We'll help you create the diet that fits your nutritional goals. 
                After we learn a little more about you, we'll create a meal plan filled with 
                custom recipies, show you where to buy the ingredients."
                buttonText="Get started"
                clickEvent={()=> setOpenSlideOver(true)}
              />
            </div>
          
            <Modal
              title="Create Wellness Plan"
              isOpen={isSlideOverOpen}
              onClose={onClose}>

              <ProgressBar progressPercentage={(activeStep + 1) / steps.length * 100} /> 
            
              <form className="h-[calc(100vh-205px)] sm:h-[calc(100vh-225px)] overflow-y-auto">
                { steps[activeStep] }
              </form>

              <div className="bg-white dark:bg-slate-900 border-t border-gray-200 dark:border-slate-700 h-[50px] flex-shrink-0 px-4 pt-3">
                <div className="flex justify-between gap-10">

                  <Button
                    onClick={() => previousStep()}
                    disabled={activeStep == 0}
                    className="w-full">
                    Previous
                  </Button>

                  { steps?.length - 1 !== activeStep ?
                    <Button
                      onClick={() => nextStep()}
                      primary={true}
                      className="w-full">
                      Next
                    </Button>
                  :
                    <Button
                      onClick={() => handleComplete()}
                      primary={true}
                      className="w-full">
                      Build my Plan!
                    </Button>
                  }

                </div>
              </div>
            
            </Modal>

            <Modal
              title="HelloLife Premium"
              isOpen={isPremiumModalOpen}
              onClose={onPrmeiumClose}>
                
              <Premium />

            </Modal>

          </div>
      }

      <Notification data={ notification } />
    </>
  );
}
 
export default Wellness;