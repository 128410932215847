import { useState, useEffect } from 'react';

import Card from '../../components/ui/Card/Card';
import Feed from '../../features/Feed/Feed';
import Post from '../../features/Post/Post';
import ProfileSearch from '../../features/ProfileSearch/ProfileSearch';
import Sidebar from '../../features/Sidebar/Sidebar';
import WhoToFollowWidget from '../../components/widgets/Social/WhoToFollowWidget';

const Home = () => {
  const [loadFeed, setLoadFeed] = useState(false);

  const onPostComplete = () => {
    setLoadFeed(true);
  }

  const onFeedRefereshComplete = () => {
    setLoadFeed(false);
  }

  useEffect(() => {
    setLoadFeed(loadFeed);
  }, [loadFeed]);

  return (
    <div className="flex justify-between h-full">

      <div className="md:py-4 md:px-4 lg:px-10 w-full overflow-y-auto">
        <Post onComplete={onPostComplete} />

        <div className="mt-1 md:mt-5">
          <Feed refresh={loadFeed} onLoadComplete={onFeedRefereshComplete} />
        </div>
      </div>

      <div className="sm:hidden md:block">
        <Sidebar className="hidden md:block md:min-w-[350px] p-3 flex flex-col space-y-3">
          <ProfileSearch />

          <Card header="Explore">
            Content here
          </Card>

          <Card header="Who to Follow">
            <WhoToFollowWidget />
          </Card>
        </Sidebar>
      </div>

    </div>
  )
}
 
export default Home;