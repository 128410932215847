import { action, thunk } from 'easy-peasy';
import { doc, setDoc, collection, addDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';

const plan = {
  id: null,
  
  data: null,

  setWellnessPlan: action((state, payload) => {
    state.data = payload;
  }),

  /* 
    Save the user's profile into Firebase so we can list users,
    use them in Security and Firebase Rules, and show profiles
  */
  saveWellnessPlan: action(async (actions, payload) => {
    const account = JSON.parse(localStorage.getItem("account"));

    setDoc(doc(firestore, account.uid, "wellness"), payload);
    
    const wellnessDoc = await getDoc(doc(firestore, account.uid, "wellness"));
    if (!wellnessDoc.exists()) {
      console.error('Wellness plan could not be saved');
    }

    return wellnessDoc.data();
  }),

  /*
    Get the user's wellness plan
  */
  fetchWellnessPlan: thunk(async (actions, state) => {
    const account = JSON.parse(localStorage.getItem("account"));
    if (!account?.uid) {
      return null;
    }

    console.log('[WELLNESS PLAN] Fetching Wellness Plan for user with ID ' + account.uid);

    const docRef = doc(firestore, account.uid, "wellness");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('[WELLNESS PLAN] Found Wellness Plan for Account with ID ' + account.uid);
    } else {
      console.log('[WELLNESS PLAN] Missing Wellness Plan for Account with ID ' + account.uid);
    }
    
    return docSnap.data();
  }),

  dietaryPreferencesAndRestrictionOptions: [
    {
      label: "Dairy-free",
      description: null
    },
    {
      label: "Gluten-free",
      description: null
    },
    {
      label: "Vegan",
      description: null
    },
    {
      label: "Vegetarian",
      description: null
    }
  ],

  medicalConditionOptions: [
    {
      label: "Cardiovascular Disease",
      description: null
    },
    {
      label: "Cholesterol",
      description: null
    },
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Hypertension",
      description: null
    }
  ],

  foodAllergyOptions: [
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Cardiovascular Disease",
      description: null
    }
  ],

  mealsPerDayOptions: [
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    },
    ,
    {
      label: "4",
      value: "4"
    },
    ,
    {
      label: "5",
      value: "5"
    }
  ],

  mealTimingOptions: [
    {
      label: "Intermittent Fasting",
      description: null
    },
    {
      label: "Multiple Small Meals",
      description: null
    }
  ],

  cookingSkillOptions: [
    {
      label: "Beginner",
      value: "Beginner"
    },
    {
      label: "Intermediate",
      value: "Intermediate"
    },
    {
      label: "Advanced",
      value: "Advanced"
    }
  ],

};

export default plan;