import { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';

import { getTodaysName } from '../../../services/GeneralInformationService';
import Badge from '../../ui/Badge/Badge';

const MealPlanWidget = () => {
  const wellnessPlan = useStoreState((state) => state.wellness.plan.data);
  const [todaysPlan, setTodaysPlan] = useState(null);

  const getTodaysPlan = async () => {
    wellnessPlan?.plan?.days?.forEach(day => {
      if (day.day === getTodaysName()) {
        setTodaysPlan(day);
      }
    });
  };
  
  useEffect(() => {
    getTodaysPlan();
  }, [wellnessPlan]);

  return (
    <section>

    {todaysPlan ?

      <ol className="divide-y divide-gray-200 text-sm leading-6 dark:divide-slate-800">
        
        <li className="py-4 flex justify-between">
          <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
            Breakfast
          </time>

          <div className="w-full">
            <div className="text-gray-700 dark:text-white">
              { todaysPlan.breakfast.description }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              { todaysPlan.breakfast.recipe }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              <ul>
              {todaysPlan.breakfast.foodOrderDetails.map((foodOrder, foodOrderIdx) => (
                <li key={foodOrderIdx} className="list-inside list-disc">{ foodOrder.quantity } - { foodOrder.ingredient }</li>
              ))}
              </ul>
            </div>
          </div>

          <div className="w-[125px] sm:w-[100px]">
            <Badge>
              { todaysPlan.breakfast.estimatedCalorieIntake } Cals
            </Badge>
          </div>
        </li>

        <li className="py-4 flex justify-between">
          <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
            Lunch
          </time>

          <div className="w-full">
            <div className="text-gray-700 dark:text-white">
              { todaysPlan.lunch.description }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              { todaysPlan.lunch.recipe }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              <ul>
              {todaysPlan.lunch.foodOrderDetails.map((foodOrder, foodOrderIdx) => (
                <li key={foodOrderIdx} className="list-inside list-disc">{ foodOrder.quantity } - { foodOrder.ingredient }</li>
              ))}
              </ul>
            </div>
          </div>

          <div className="w-[125px] sm:w-[100px]">
            <Badge>
              { todaysPlan.lunch.estimatedCalorieIntake } Cals
            </Badge>
          </div>
        </li>

        <li className="py-4 flex">
          <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
            Dinner
          </time>

          <div className="w-full">
            <div className="text-gray-700 dark:text-white">
              { todaysPlan.dinner.description }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              { todaysPlan.dinner.recipe }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              <ul>
              {todaysPlan.dinner.foodOrderDetails.map((foodOrder, foodOrderIdx) => (
                <li key={foodOrderIdx} className="list-inside list-disc">{ foodOrder.quantity } - { foodOrder.ingredient }</li>
              ))}
              </ul>
            </div>
          </div>

          <div className="w-[125px] sm:w-[100px]">
            <Badge>
              { todaysPlan.dinner.estimatedCalorieIntake } Cals
            </Badge>
          </div>
        </li>

        <li className="py-4 flex justify-between">
          <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
            Snacks
          </time>

          <div className="w-full">
            <div className="text-gray-700 dark:text-white">
              { todaysPlan.snacks.description }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              { todaysPlan.snacks.recipe }
            </div>
            <div className="text-gray-500 dark:text-slate-400">
              <ul>
              {todaysPlan.snacks.foodOrderDetails.map((foodOrder, foodOrderIdx) => (
                <li key={foodOrderIdx} className="list-inside list-disc">{ foodOrder.quantity } - { foodOrder.ingredient }</li>
              ))}
              </ul>
            </div>
          </div>

          <div className="w-[125px] sm:w-[100px]">
            <Badge>
              { todaysPlan.snacks.estimatedCalorieIntake } Cals
            </Badge>
          </div>
        </li>

      </ol>

    : 
      ''
    }

    </section>
  )
}

export default MealPlanWidget;