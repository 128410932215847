import { action, thunk } from 'easy-peasy';
import { doc, setDoc, collection, addDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';

const account = {
  data: null,

  setAccount: action((state, account) => {
    state.data = account;
  }),

  /* 
    Save the account's profile into Firebase so we can list accounts,
    use them in Security and Firebase Rules, and show profiles
  */
  saveAccount: thunk(async (actions, account, { getState }) => {
    try {
      console.log('[ACCOUNT] Saving account with ID ' + account.uid);
  
      // Save the account to Firestore
      await setDoc(doc(firestore, "accounts", account.uid), account);
  
      // Fetch the saved account to ensure it was written correctly
      const accountDoc = await getDoc(doc(firestore, "accounts", account.uid));
      if (!accountDoc.exists()) {
        console.error('[ACCOUNT] Account could not be saved');
        return null;
      }
  
      const savedAccount = accountDoc.data();
  
      // Optional: Set the saved account in the store (if needed)
      // actions.setAccount(savedAccount); // Example of setting the account in state
  
      return savedAccount;
  
    } catch (error) {
      console.error('[ACCOUNT] Error saving account:', error);
      return null;
    }
  }),  

  /*
    Get the account's profile
  */
  fetchAccount: thunk(async (actions, id, state) => {
    console.log('[ACCOUNT] Fetching Account with ID ' + id);

    const docRef = doc(firestore, "accounts", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('[ACCOUNT] Found Account with ID ' + id);
    } else {
      console.log('[ACCOUNT] Missing Account with ID ' + id);
    }
    
    return docSnap.data();
  })

};

export default account;
