import { useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

const Modal = ({
  title = null,
  children = '',
  isOpen = false,
  onClose}) => {

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Dialog open={isModalOpen} onClose={onClose} className="relative z-90">
      <div
        className="fixed inset-0 bg-white dark:bg-slate-950 bg-opacity-70 dark:bg-opacity-80 transition transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-start mt-0 sm:mt-auto justify-center text-center sm:items-start sm:p-0">
          <DialogPanel
            className="relative transition transform overflow-hidden rounded-none min-w-auto sm:min-w-[600px] sm:rounded-lg bg-white dark:bg-slate-900 pb-4 text-left shadow-2xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:w-[500px] data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div>
                {title ? 
                  <div className="py-5 border-b dark:border-slate-700 text-center">
                    <DialogTitle as="h3" className="text-xl text-gray-900 dark:text-white font-bold leading-6">
                      { title }
                    </DialogTitle>

                    <button 
                      className="absolute top-3 right-3 p-2 rounded-full text-gray-900 bg-gray-100 hover:bg-gray-200 dark:text-white dark:bg-slate-700 dark:hover:bg-slate-600"
                      onClick={onClose}>
                      <XMarkIcon className="w-6 h-6" />
                    </button>
                  </div>
                :
                  ''
                }

                <div>
                  { children }
                </div>

              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
 
export default Modal;