import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useController } from 'react-hook-form';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SelectMenu = ({
  control, // required
  name, // required
  options = [],
  grouping = false,
  helperText,
  placeholder = "Select an Option",
  ariaDescribedBy = '',
  isErrored = false,
  required = false,
  disabled = false,
  value = '',
  style,
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue: placeholder ? undefined : options[0],
    value,
    rules: {
      required: { value: required, message: 'This is a required field' },
    },
  });

  return (
    <div style={style}>
      <div>
        <select
          ref={field.ref} // send input ref, so we can focus on input when error appear
          onChange={field.onChange} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          value={field.value ?? ''} // input value
          name={field.name} // send down the input name
          disabled={disabled}
          aria-describedby={ariaDescribedBy}
          className={classNames(
            isErrored ? 'text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500' : 'text-gray-800 dark:text-slate-300 ring-gray-300 dark:ring-slate-700 placeholder:text-gray-400 focus:ring-green-600',
            'block w-full rounded-md border-0 py-1.5 bg-white dark:bg-slate-800 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 dark:disabled:bg-slate-800 disabled:text-gray-500 dark:disabled:text-slate-700 disabled:ring-gray-200 dark:disabled:ring-slate-700',
          )}
        >

          {placeholder && (
            <option key="none" value="">
              {placeholder}
            </option>
          )}

          {grouping ? (
            options.map((option, idx) => (
              <optgroup key={idx} label={option.label}>
                {option.group.map((opt) => (
                  <option key={opt.value} value={opt.value} disabled={opt.disabled}>
                    {opt.label}
                  </option>
                ))}
              </optgroup>
            ))
          ) : (
            options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))
          )}

        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" 
          style={{ display: invalid ? 'block' : 'none' }}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500"
        style={{ display: helperText ? 'block' : 'none' }}>
        { helperText }
      </p>
      <p className="mt-2 text-sm text-red-600"
        style={{ display: invalid ? 'block' : 'none' }}>
        { invalid && error.message }
      </p>
    </div>
  )
}

export default SelectMenu;