import { action, thunk } from 'easy-peasy';
import { doc, setDoc, collection, addDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../firebase';

const profile = {
  data: null,

  myProfile: null,

  currentProfile: null,

  recommendedProfiles: null,

  setProfile: action((state, profile) => {
    state.data = profile;
  }),

  setMyProfile: action((state, profile) => {
    state.myProfile = profile;
  }),

  setCurrentProfile: action((state, profile) => {
    state.currentProfile = profile;
  }),

  setRecommendedProfiles: action((state, profiles) => {
    state.recommendedProfiles = profiles;
  }),

  saveProfile: thunk(async (actions, profile, { getState }) => {
    console.log('[PROFILE] Saving profile with ID ' + profile.uid);
  
    await setDoc(doc(firestore, "profiles", profile.uid), profile);
    
    const profileDoc = await getDoc(doc(firestore, "profiles", profile.uid));
    if (!profileDoc.exists()) {
      console.error('Profile could not be saved');
      return null;
    }
  
    const savedProfile = profileDoc.data();
    actions.setMyProfile(savedProfile);  // Update the state with the saved profile
  
    return savedProfile;
  }),

  /*
    Get the user's profile
  */
  fetchProfileByUid: thunk(async (actions, uid, state) => {
    console.log('[PROFILE] Fetching Profile for Account ' + uid);

    const profilesCollectionRef = collection(firestore, "profiles");
    const q = query(profilesCollectionRef, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    const profiles = querySnapshot.docs.map(doc => doc.data());

    if (profiles?.length > 0) {
      console.log('[PROFILE] Found profile');
      return profiles[0];
    } else {
      console.log('[PROFILE] Could not find profile');
      return;
    }
  }),

  /*
    Get the user's profile
  */
    fetchProfileByUsername: thunk(async (actions, username, state) => {
      console.log('[PROFILE] Fetching Profile with username ' + username);
  
      const profilesCollectionRef = collection(firestore, "profiles");
      const q = query(profilesCollectionRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);
      const profiles = querySnapshot.docs.map(doc => doc.data());
  
      if (profiles?.length > 0) {
        console.log('[PROFILE] Found profile');
        return profiles[0];
      } else {
        console.log('[PROFILE] Could not find profile');
        return;
      }
  
    }),

  /*
    Get profiles
  */
  fetchProfiles: thunk(async (actions, state) => {
    console.log('[PROFILE] Fetching profiles');
    const profilesCollectionRef = collection(firestore, "profiles");
    const q = query(profilesCollectionRef);
    const querySnapshot = await getDocs(q);
    const profiles = querySnapshot.docs.map(doc => doc.data());

    if (profiles) {
      console.log('[PROFILE] Found profiles');
    } else {
      console.log('[PROFILE] Could not find profiles');
    }

    return profiles;
  })

};

export default profile;
