import OpenAI from "openai";

const openai = new OpenAI({ 
  apiKey: 'sk-svcacct-ieijLkLu21xIz3bYAoeVv6G-48OY-fI1ICy-JJVWc9wqP_C3pIMir04gso2Klytdx15jLBbzjT3BlbkFJlawaG-kzdA97iuTVH4cnnV5Vj1lG4epiEqPBD-Bb2-hBav2GN1I1l-BCpJ5b3JpgtWBMVWV5wA',
  dangerouslyAllowBrowser: true
});

export async function askChatGpt(role, content) {
  const completion = await openai.chat.completions.create({
    messages: [{ role: role, content: content }],
    model: "gpt-4o-mini",
  });

  return completion.choices[0];
}