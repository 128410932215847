import { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { useForm } from 'react-hook-form';

import Button from "../../components/ui/Form/Button/Button";
import InputField from "../../components/ui/Form/InputField/InputField";

import SelectMenu from '../../components/ui/Form/SelectMenu/SelectMenu';

const AddRecordModal = ({onClose}) => {
  const account = useStoreState((state) => state.account.data);
  const activity = useStoreState((state) => state.wellness.activity.data);
  const saveActivity = useStoreActions((actions) => actions.wellness.activity.saveActivity);

  const [recordType, setRecordType] = useState("Weight");

  const { 
    reset, 
    formState, 
    handleSubmit, 
    control, 
    setError, 
    formState: { errors, isValid, isDirty, isSubmitting } 
  } = useForm({
    defaultValues: {
      details: {
        type: null,
        weightPounds: null
      }
    },
   
    values: {
      ...activity
    }
  });

  const onRecordTypeSelect = (recordType) => {
    console.log('record type', recordType);
    setRecordType(recordType);
  }

  const handleComplete = () => {
    console.log('complete');
    handleSubmit(onSubmit, onError)();
  }

  const onSubmit = async (record, e) => {
    console.log('onsubmit');
    await saveActivity(record);
    onClose(true);
  }

  const onError = (errors, e) => console.log(errors, e);

  const recordTypeOptions = [
    {
      value: 'Meal',
      label: 'Meal'
    },
    {
      value: 'Weight',
      label: 'Weight'
    },
    {
      value: 'Workout',
      label: 'Workout'
    }
  ]

  return (    
    <form>
      {/*
      <SelectMenu
        name="details.type"
        placeholder='What would you like to record?'
        control={control}
        options={recordTypeOptions}
        onChange={onRecordTypeSelect}
        required
      />
      */}

      <div className={recordType ? 'block' : 'hidden'}>

        { 
          'Meal' == recordType ?
            <div>Meal</div>

        : 'Weight' == recordType ?
            <InputField 
              name="details.weightPounds" 
              control={control} 
              placeholder='Enter current weight...' 
              required 
            />

        : 'Workout' == recordType ?
            <div>Workout</div>

        : 
          ''

        }
      
        <div className="flex mt-4">
          <Button
            primary={true}
            className="m-auto mr-4"
            disabled={!formState.isValid} 
            onClick={() => handleComplete()}>
            Add { recordType }
          </Button>
        </div>

      </div>

    </form>
  )
}
 
export default AddRecordModal;