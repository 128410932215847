import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import { 
  ArrowUturnRightIcon, 
  ChatBubbleOvalLeftIcon, 
  HandThumbUpIcon, 
  PaperAirplaneIcon 
} from '@heroicons/react/24/outline';

import {
  CheckBadgeIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/24/solid';

import { convertDateTimeToString } from '../../services/DateTimeService';

import accountImagePlaceholder from '../../assets/images/user_image_placeholder.jpg';
import Card from '../../components/ui/Card/Card';
import Button from '../../components/ui/Form/Button/Button';

const SocialCard = ({
  postUid,
  accountUid,
  name,
  photoURL,
  createdAt,
  premiumAccount,
  children
}) => {

  const account = useStoreState((state) => state.account.data);

  const fetchProfileByUid = useStoreActions((actions) => actions.profile.fetchProfileByUid);

  const savePost = useStoreActions((actions) => actions.post.savePost);
  const deletePost = useStoreActions((actions) => actions.post.deletePost);

  const [hidden, setHidden] = useState(false);

  const deletePostAction = async (postUid) => {
    await deletePost(postUid);
    setHidden(true);
  }
  
  const editPostAction = async (postUid) => {
    console.log('Edit post');
  }

  useEffect(() => {
  }, [hidden]);

  const navigate = useNavigate();

  const goToProfile = async () => {
    const profile = await fetchProfileByUid(accountUid);
    navigate('/hello/' + profile?.username);
  }

  return (
    <div className={hidden ? 'hidden' : 'block'}>   
      <Card footer={
        <div className="flex justify-between gap-1 md:gap-2">
          <Button border={false} className="w-full bg-transparent dark:bg-slate-900">
            <div className="flex">
              <div className="flex m-auto">
                <HandThumbUpIcon className="m-auto mr-2 h-5 w-5" />
                Like
              </div>
            </div>
          </Button>
          <Button border={false} className="w-full bg-transparent dark:bg-slate-900">
            <div className="flex">
              <div className="flex m-auto">
                <ChatBubbleOvalLeftIcon className="m-auto mr-2 h-5 w-5" />
                Comment
              </div>
            </div>
          </Button>
          <Button border={false} className="w-full bg-transparent dark:bg-slate-900">
            <div className="flex">
              <div className="flex m-auto">
                <PaperAirplaneIcon className="m-auto mr-2 h-5 w-5" />
                Send
              </div>
            </div>
          </Button>
          <Button border={false} className="w-full bg-transparent dark:bg-slate-900">
            <div className="flex">
              <div className="flex m-auto">
                <ArrowUturnRightIcon className="m-auto mr-2 h-5 w-5" />
                Share
              </div>
            </div>
          </Button>
        </div>
      }>

        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0 hover:cursor-pointer" onClick={() => goToProfile()}>
            <img
              alt=""
              src={ photoURL ? photoURL : accountImagePlaceholder }
              className="inline-block h-10 w-10 rounded-full"
            />
          </div>
          <div className="min-w-0 flex-1">
            <div className="relative">
              <div className="overflow-hidden rounded-lg">
                <div className="flex text-md font-semibold text-black dark:text-white">
                  {name}
                  {premiumAccount ? 
                    <CheckBadgeIcon className="ml-1 m-auto h-4 w-4 shrink-0 text-indigo-500" />
                  : ''
                  }
                </div>
                <div className="text-sm font-light text-black dark:text-slate-400">
                  { convertDateTimeToString(createdAt) }
                </div>
                <div className="mt-3">
                  { children }
                </div>
              </div>
            </div>

            {account.uid === accountUid ?
              <Menu as="div" className="absolute top-5 right-5">
                <div>
                  <MenuButton>
                    <EllipsisHorizontalIcon aria-hidden="true" className="h-7 w-7" />
                  </MenuButton>
                </div>

                <MenuItems
                  transition="true"
                  className="absolute right-0 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    {/*
                    <MenuItem>
                      <Button
                        border={false}
                        boldFont={false}
                        onClick={() => editPostAction(postUid)}
                        className="w-full text-left"
                      >
                        Edit Post
                      </Button>
                    </MenuItem>
                    */}
                    <MenuItem>
                      <Button
                        border={false}
                        boldFont={false}
                        onClick={() => deletePostAction(postUid)}
                        className="w-full text-left"
                      >
                        Delete
                      </Button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
            : 
            ''
            }

          </div>
        </div>
        
      </Card>
    </div>
  );
}

export default SocialCard;