import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {useNavigate} from 'react-router-dom';
import {  
    signInWithEmailAndPassword,
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider
} from 'firebase/auth';
import logo from '../../assets/images/logo.png';
import InputField from '../../components/ui/Form/InputField/InputField';
import Button from '../../components/ui/Form/Button/Button';
 
const Login = () => {
    const auth = getAuth();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState();

    const fetchAccount = useStoreActions((actions) => actions.account.fetchAccount);
    const saveAccount = useStoreActions((actions) => actions.account.saveAccount);
    const setAccount = useStoreActions((actions) => actions.account.setAccount);
    const account = useStoreState((state) => state.account.data);

    const { 
        reset, 
        formState,
        handleSubmit, 
        getValues, 
        control, 
        setError,
        formState: { errors, isValid, isDirty, isSubmitting } 
    } = useForm({
        values: {
            ...account
        }
    });

    const onLoginWithGoogle = () => {
        console.log('[LOGIN] Logging in with Google provider');
        signInWithPopup(auth, new GoogleAuthProvider())
        .then(async (result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            
            fetchAccount(result.user.uid).then(async account => {
                if (!account) {
                    const newAccount = await saveAccount({
                        uid: result.user.uid,
                        firstName: result.user.displayName ? 
                            result.user.displayName?.substring(0, result.user.displayName?.indexOf(' ')) : null,
                        lastName: result.user.displayName ? 
                            result.user.displayName?.substring(result.user.displayName?.indexOf(' ')) : null,
                        email: result.user.email,
                        photoURL: result.user.photoURL,
                        providerData: result.user.providerData,
                        isPremium: false,
                        timezone: null
                    });
                    await setAccount(newAccount);
                    localStorage.setItem('account', JSON.stringify(newAccount));
                } else {
                    await setAccount(account);
                    localStorage.setItem('account', JSON.stringify(account));
                }

                navigate('/');
            });

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if ("auth/invalid-credential" == errorCode) {
                setErrorMessage("Invalid credentials");
            }
        });
    }
       
    const onLogin = (e) => {
        e.preventDefault();

        console.log('[LOGIN] Logging in as user', getValues("email"));

        signInWithEmailAndPassword(auth, getValues("email"), getValues("password"))
        .then(async (userCredential) => {

            await fetchAccount(userCredential.user.uid).then(async account => {
                localStorage.setItem('account', JSON.stringify(account));
                await setAccount(account);

                navigate('/');
            });

        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if ("auth/invalid-credential" == errorCode) {
                setErrorMessage("Invalid credentials");
            }
        });
    }
 
    return(
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        src={logo}
                        alt="HelloLife"
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-slate-400">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    
                    <div className="bg-white dark:bg-slate-900 px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        
                        <form action="#" method="POST" className="space-y-6">
                            
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="current-email"
                                        control={control}
                                        required
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-800 dark:text-white">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <InputField
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        control={control}
                                        required
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    onClick={onLogin}
                                    primary={true}
                                    disabled={!formState.isValid}
                                >
                                    Sign in
                                </Button>
                            </div>

                            {errorMessage ? 
                                <div className="w-full p-2 rounded-md bg-red-100 dark:bg-red-700 dark:text-white text-sm text-center">
                                    {errorMessage}
                                </div>
                            : '' }

                        </form>

                        <div>

                            <div className="relative mt-10">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-200 dark:border-slate-600" />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900 dark:bg-slate-600 dark:text-slate-300">
                                        Or continue with
                                    </span>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-2 gap-4">
                                <Button
                                    onClick={() => onLoginWithGoogle()}>
                                    <div className="flex items-center justify-center gap-3">
                                        <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                            <path
                                                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                                fill="#EA4335"
                                            />
                                            <path
                                                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                                fill="#4285F4"
                                            />
                                            <path
                                                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                                fill="#FBBC05"
                                            />
                                            <path
                                                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                                fill="#34A853"
                                            />
                                        </svg>
                                        <span className="text-sm font-semibold leading-6">Google</span>
                                    </div>
                                </Button>
                            </div>

                        </div>

                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <a href="/register" className="font-semibold leading-6 text-green-600 hover:text-green-500">
                            Sign up now
                        </a>
                    </p>

                </div>

            </div>
        </>
    )
}
 
export default Login;