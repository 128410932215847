import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useController } from 'react-hook-form';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const InputField = ({
  control, // required
  name, // required
  type = "text",
  leadingValue,
  trailingValue,
  placeholder = "",
  helpText = "",
  disabled = false,
  required = false,
  isErrored = false,
  errorMessage = "Not a valid value"
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: { value: required, message: 'This is a required field' },
    },
  });

  return (
    <>
      <div className="relative">

        {leadingValue ? 
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{leadingValue}</span>
          </div>
        : '' }

        <input
          name={field.name}
          control={control}
          onChange={field.onChange} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          value={field.value ?? ''}
          type={type}
          className={classNames(
            isErrored ? 'text-red-500 ring-red-500 placeholder:text-red-t00 focus:ring-red-500' : 'text-gray-800 dark:text-slate-300 ring-gray-300 dark:ring-slate-700 placeholder:text-gray-400 focus:ring-green-600',
            'block w-full rounded-md border-0 py-1.5 bg-white dark:bg-slate-800 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 dark:disabled:bg-slate-800 disabled:text-gray-500 dark:disabled:text-slate-700 disabled:ring-gray-200 dark:disabled:ring-slate-700',
          )}       
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          aria-invalid={isErrored}
          aria-describedby={name + "-error"}
          autoComplete="off"
        />

        {trailingValue ? 
          <div className={classNames(
            type.toLowerCase() == 'number' ? 'right-5' : 'right-0',
            'pointer-events-none absolute inset-y-0 flex items-center pr-3',
          )}>
            <span id={field.name + '-trailing'} className="text-gray-500 dark:text-slate-500 sm:text-sm">
              {trailingValue}
            </span>
          </div>
        : '' }
        
        {/*
        <div className={isErrored ? "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" : "hidden"}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
        */}
      </div>
      <p className={helpText ? "mt-2 text-sm text-gray-500 dark:text-slate-600" : "hidden"} id={name + "-help-text"}>
        {helpText}
      </p>
      <p className={isErrored ? "mt-2 text-sm text-red-600" : "hidden"} id={name + "-error"}>
        {errorMessage}
      </p>
    </>
  )
}

export default InputField;