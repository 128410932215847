import { action, thunk } from 'easy-peasy';
import { collection, addDoc, getDocs, query, where, orderBy, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';

import { getDateTime } from '../../../services/DateTimeService';

const post = {
  data: null,

  setPosts: action((state, post) => {
    state.data = post;
  }),

  /**
   * Save a post
   */
  savePost: action(async (actions, post) => {
    post.createdAt = getDateTime();

    console.log('[POSTS] Saving post');
    let postsCollectionRef = collection(firestore, 'posts');
    
    const docRef = await addDoc(postsCollectionRef, post);
    post.uid = docRef.id;

    await setDoc(doc(firestore, "posts", post.uid), post);
  }),

  /**
   * Delete a post
   */
  deletePost: action(async (actions, postUid) => {
    console.log('[POSTS] Deleting post', postUid);
    const docRef = doc(firestore, "posts", postUid);
    await deleteDoc(docRef);
  }),

  /*
    Get a posts
  */
  fetchPosts: thunk(async (actions, accountUid, state) => {
    console.log(accountUid ? '[POSTS] Fetching posts for account' + accountUid : '[POSTS] Fetching posts');
  
    const postsCollectionRef = collection(firestore, "posts");

    const q = accountUid ? 
      query(postsCollectionRef, where("accountUid", "==", accountUid), orderBy('createdAt', 'desc')) :
      query(postsCollectionRef, orderBy('createdAt', 'desc'));

    const querySnapshot = await getDocs(q);
    const posts = querySnapshot.docs.map(doc => doc.data());
    return posts;
  })

};

export default post;