import { useStoreActions, useStoreState } from 'easy-peasy';
import { useState } from 'react';

import AdsComponent from '../../components/ads/AdsComponent';
import Card from '../../components/ui/Card/Card';
import MacrosWidget from '../../components/widgets/Wellness/MacrosWidget';
import HeartHealthyWidget from '../../components/widgets/Wellness/HeartHealthyWidget';
import LowCarbWidget from '../../components/widgets/Wellness/LowCardWidget';
import CustomSummaryWidget from '../../components/widgets/Wellness/CustomSummaryWidget';
import MealPlanWidget from '../../components/widgets/Wellness/MealPlanWidget';
import Button from '../../components/ui/Form/Button/Button';
import WorkoutWidget from '../../components/widgets/Wellness/WorkoutWidget';
import Modal from '../../components/ui/Modal/Modal';
import AddRecordModal from './AddRecordModal';
import Sidebar from '../../features/Sidebar/Sidebar';
import ActivityWidget from '../../components/widgets/Wellness/ActivityWidget';
import WellnessCards from './WellnessCards';

const WellnessDashboard = () => { 
  const account = useStoreState((state) => state.account.data);

  const fetchWellnessActivities = useStoreActions((actions) => actions.wellness.activity.fetchActivities);
  const setWellnessActivities = useStoreActions((actions) => actions.wellness.activity.setActivities);

  const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);

  const onAddRecordModalClose = async () => {
    const updatedActivities = await fetchWellnessActivities(account.uid);
    await setWellnessActivities(updatedActivities);
    setIsAddRecordModalOpen(false);
  }

  return (    
    <div className="flex h-full justify-between">

      <div className="overflow-y-auto w-full">

        <div className="py-4 md:py-4 px-0 md:px-4 lg:px-10 grid grid-cols-1 gap-x-4 gap-y-4">

          <Card 
            header={"Activity"} 
            footer={
              <div className="float-right mb-4 flex gap-5">
                <Button 
                  primary={true} 
                  onClick={() => setIsAddRecordModalOpen(true)}>
                  Add Record
                </Button>
              </div>
          }>
            <ActivityWidget />
          </Card>

          <div className="sm:block md:hidden">
            <WellnessCards />
          </div>

          <AdsComponent dataAdSlot='7071684811' adFormat='horizontal' />

          <Card 
            header={"Today's Meal Plan"} 
            footer={
              <div className="float-right mb-4 flex gap-5">
                <Button primary={true}>
                  Order Groceries
                </Button>
                <Button>
                  Weekly Plan
                </Button>
              </div>
          }>
            <MealPlanWidget />
          </Card>

          <Card header={"Weekly Workout Schedule"}>
            <WorkoutWidget />
          </Card>

          <AdsComponent dataAdSlot='7071684811' adFormat='horizontal' />

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-4">
            <Card header={"Macros"} showPremiumLock={!account?.isPremium}>
              <MacrosWidget />
            </Card>

            <Card header={"Heart Healthy"} showPremiumLock={!account?.isPremium}>
              <HeartHealthyWidget />
            </Card>

            <Card header={"Low Carb"} showPremiumLock={!account?.isPremium}>
              <LowCarbWidget />  
            </Card>

            <Card header={"Custom Summary"} showPremiumLock={!account?.isPremium}>
              <CustomSummaryWidget />
            </Card>
          </div>

        </div>

        <Modal
          title="Add Record"
          isOpen={isAddRecordModalOpen}
          onClose={onAddRecordModalClose}>

          <div className="p-2">
            <AddRecordModal onClose={onAddRecordModalClose} />
          </div>

        </Modal>

      </div>

      <Sidebar className="hidden md:block md:min-w-[350px] p-3">

        <WellnessCards />

      </Sidebar>
      
    </div>
  )
}
 
export default WellnessDashboard;