import { useStoreState } from 'easy-peasy';
import Badge from '../../ui/Badge/Badge';

const WorkoutWidget = () => { 
  const wellnessPlan = useStoreState((state) => state.wellness.plan.data);

  return (
    <section>

      <ol className="divide-y divide-gray-200 text-sm leading-6 dark:divide-slate-800">
        {wellnessPlan?.plan?.days?.map((day, dayIdx) => (

          <li key={dayIdx} className="py-4 flex justify-between">
            <time dateTime="2022-01-17" className="w-[100px] flex-none font-semibold text-green-600">
              { day.day }
            </time>

            {day.workouts?.map((workout, workoutIdx) => (
              <div key={workoutIdx} className="w-full">
                <div className="text-gray-700 dark:text-white">
                  { workout.title }
                </div>
                <div className="text-gray-500 dark:text-slate-400">
                  { workout.description }
                </div>
              </div>
            ))}

            <div className="w-[125px] sm:w-[100px]">
              <Badge>
                { day.workouts[0]?.estimatedCalorieBurn } Cals
              </Badge>
            </div>
            
          </li>
        
        ))}

      </ol>

    </section>
  )
}

export default WorkoutWidget;