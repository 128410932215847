import Button from "../Form/Button/Button";

const Splash = ({
  titleLine1, 
  titleLine2, 
  description, 
  buttonText, 
  isButtonDisabled = false,
  clickEvent}) => { 

  return (     
    <div className="rounded-lg shadow bg-white dark:bg-slate-900">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h3 className="text-xl font-light tracking-tight text-gray-800 dark:text-white sm:text-2xl">
            { titleLine1 }
          </h3>
          <h2 className="text-3xl tracking-tight text-green-500 sm:text-4xl">
            { titleLine2 }
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-500 dark:text-slate-400">
            { description }
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
           <Button
                onClick={clickEvent}
                disabled={isButtonDisabled}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Splash;