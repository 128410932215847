import { useStoreState } from 'easy-peasy';

import SelectMenu from "../../ui/Form/SelectMenu/SelectMenu";
import {GENDER_OPTIONS} from '../../../services/GeneralInformationService';
import InputField from "../../ui/Form/InputField/InputField";

const WellnessFormPersonalInfo = ({ control }) => {
  const account = useStoreState((state) => state.account.data);

  return (
    <section>
      <div className="text-xl text-center font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3">
        Hi { account?.firstName }!
      </div>

      <div className="text-sm text-center font-light leading-6 text-gray-900 dark:text-white md:pb-2">
        <p>We're happy you're here.</p>
        <p>Let's get to know a little about you.</p>
      </div>

      <div className="text-left space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-slate-800 sm:py-0">

        {/* Gender */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="personalInfo.gender"
              className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
            >
              Gender
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectMenu
              name="personalInfo.gender"
              value={GENDER_OPTIONS[0]?.value}
              control={control}
              options={GENDER_OPTIONS}
              required
            />
          </div>
        </div>

        {/* Age */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="personalInfo.age"
              className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
            >
              Age
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
                type="number"
                name="personalInfo.age"
                control={control}
                required
            />
          </div>
        </div>

        {/* Height */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="personalInfo.height"
              className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
            >
              Height
            </label>
          </div>
          <div className="flex gap-5 sm:col-span-2">
            <InputField
              type="number"
              name="personalInfo.heightFeet"
              control={control}
              trailingValue="ft"
              required
            />
            <InputField
              type="number"
              name="personalInfo.heightInches"
              control={control}
              trailingValue="in"
              required
            />
          </div>
        </div>

        {/* Weight */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="personalInfo.weight"
              className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
            >
              Weight
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="number"
              name="personalInfo.weightPounds"
              helpText="It's OK to estimate. You can update this later."
              control={control}
              trailingValue="lbs"
              required
            />
          </div>
        </div>

        {/* Goal Weight */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="personalInfo.weight"
              className="block text-sm font-medium leading-6 text-gray-800 dark:text-white sm:mt-1.5"
            >
              Goal Weight
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="number"
              name="goals.weightPounds"
              helpText="Don't worry. This doesn't affect your daily calorie goal and you can always change it later."
              control={control}
              trailingValue="lbs"
              required
            />
          </div>
        </div>

      </div>

    </section>
  );
}
 
export default WellnessFormPersonalInfo;