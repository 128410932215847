import { MagnifyingGlassIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import logo from '../../assets/images/logo.png';
import Button from '../../components/ui/Form/Button/Button';

const Topnav = () => {
  return (
    <div className="w-full bg-white dark:bg-slate-900">
      
      <div className="flex h-16 shrink-0 items-center pl-4 justify-between">
        <div className="flex">
          <img
            className="h-8 w-auto md:m-auto xl:m-0"
            src={logo}
            alt="HelloLife"
          />
          <div className="ml-2 text-xl text-slate-800 dark:text-white md:hidden xl:block">HelloLife</div>
        </div>
        <div className="flex gap-2 pr-2">
          <Button className="bg-transparent dark:bg-slate-900">
            <MagnifyingGlassIcon className="h-5 w-5" />
          </Button>
          <Button className="bg-transparent dark:bg-slate-900">
            <EnvelopeIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>

    </div>
  )
}

export default Topnav;