import { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import {
  BellIcon,
  Cog6ToothIcon,
  EnvelopeIcon,
  HeartIcon,
  HomeIcon
} from '@heroicons/react/24/outline';

import {
  CheckBadgeIcon
} from '@heroicons/react/24/solid';

import accountImagePlaceholder from '../../assets/images/user_image_placeholder.jpg';
import logo from '../../assets/images/logo.png';

import AdsComponent from '../../components/ads/AdsComponent';

const Sidenav = () => {
  const navigate = useNavigate();

  const account = useStoreState((state) => state.account.data);
  const myProfile = useStoreState((state) => state.profile.myProfile);

  useEffect(() => {
    // Reload when my profile changes
  }, [myProfile]);

  const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, displayMobile: true },
    { name: 'Wellness', href: '/wellness', icon: HeartIcon, displayMobile: true },
    { name: 'Notifications', href: '/notifications', icon: BellIcon, displayMobile: true },
    { name: 'Messages', href: '/messages', icon: EnvelopeIcon, displayMobile: false },
    { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, displayMobile: true }
  ];

  const [currentTarget, setCurrentTarget] = useState('/');
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  const navigateTo = (href) => {
    setCurrentTarget(href);
    navigate(href);
  };

  return (
    <div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:z-50 md:flex xl:w-80 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white dark:bg-slate-900 dark:border-slate-800 px-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto md:m-auto xl:m-0"
              src={logo}
              alt="HelloLife"
            />
            <div className="ml-2 text-xl text-slate-800 dark:text-white md:hidden xl:block">HelloLife</div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 xl:space-y-1 md:space-y-3">
                  {navigation.map((item, index) => (
                    <li key={item.name} className="hover:cursor-pointer">
                      <button
                        onClick={() => navigateTo(item.href)}
                        className={classNames(
                          currentTarget == item.href
                            ? 'text-white bg-green-600 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-600'
                            : 'text-black dark:text-white hover:bg-gray-100 dark:hover:bg-slate-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 w-full'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            currentTarget == item.href
                            ? 'text-white bg-green-600 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-600'
                            : 'text-black dark:text-white',
                            'xl:h-6 xl:w-6 shrink-0 xl:m-0 md:m-auto md:h-7 md:w-7'
                          )}
                          aria-hidden="true"
                        />
                        <div className="md:hidden xl:block">
                          {item.name}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="-mx-4 mt-auto">
                <div className="p-2 md:hidden xl:block">
                  <AdsComponent dataAdSlot='3540468062' />
                </div>

                <button
                  className="flex gap-x-3 px-5 py-3 hover:bg-gray-100 hover:dark:bg-slate-800 w-full"
                  onClick={()=> navigateTo('/settings') }
                >
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50 m-auto mx-0"
                    src={ account?.photoURL ? account.photoURL : accountImagePlaceholder }
                    referrerPolicy="no-referrer"
                    alt=""
                  />
                  <div className="md:hidden xl:block">
                    <div className="flex text-black text-md font-semibold dark:text-white" aria-hidden="true">
                      { account?.firstName } { account?.lastName }
                      {account?.isPremium ? 
                        <CheckBadgeIcon className="ml-1 m-auto h-5 w-5 shrink-0 text-indigo-500" />
                      : ''
                      }
                    </div>
                    { myProfile ?
                      <div className="flex text-gray-600 text-sm dark:text-slate-300" aria-hidden="true">
                        {myProfile ? '@' + myProfile.username : <div className="h-5" />}
                      </div>
                    : 
                      ''
                    }
                  </div>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="fixed w-full h-[69px] overflow-hidden bottom-0 z-40 flex items-center backdrop-blur-sm bg-white dark:bg-slate-900 shadow-sm sm:px-6 md:hidden">
        <div className="flex justify-between w-full mx-0 md:mx-5 sm:mx-20 h-full">
          {navigation.map((item, index) => (
            item.displayMobile ? 

              <div key={item.name} 
                className={classNames(
                  currentTarget == item.href ? 'border-green-600' : 'border-white dark:border-slate-900',
                  "inline-flex flex-col w-20 pt-1 overflow-hidden group border-t-2"
                )}>
                <button 
                  type="button" 
                  className={classNames(
                    currentTarget == item.href ? 'text-green-600' : 'text-gray-400',
                    "inline-flex flex-col items-center justify-center group"
                  )}
                  onClick={() => navigateTo(item.href)}>
                    
                    <item.icon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />

                    <span className="text-xs">
                      {item.name}
                    </span>
                    
                </button>

              </div>

            : 
            
              ''

          ))}
        </div>
      </div>

    </div>
  )
}

export default Sidenav;