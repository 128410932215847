import { action, thunk } from 'easy-peasy';

const event = {
  id: null,
  
  data: null,

  setWellnessPlan: action((state, payload) => {
    state.data = payload;
  }),

  saveWellnessPlan: thunk(async (actions, payload, { getStoreActions }) => {
    console.log('Saving wellness plan', payload);
    console.log('Saving wellness plan JSON', JSON.stringify(payload));
    /*
    try {
      actions.startLoading();

      const { id, defaultPaymentMethodId, updatedAt, ...patch } = payload;

      const result = await API.graphql({
        query: UPDATE_PROFILE,
        variables: { id, patch },
      });

      if (result.errors?.length) {
        getStoreActions().notification.throwError({
          msg: 'Error occurred saving profile',
          consoleMsg: '[PROFILE] Save Profile Failure ===> ',
          data: result.errors,
        });
      } else {
        actions.setFullProfile(result.data.updateUser);
        getStoreActions().notification.showSuccess({
          msg: 'Profile changes saved successfully.'
        });
      }

      actions.finishLoading();
    } catch (err) {
      getStoreActions().notification.throwError({
        msg: 'Error occurred saving profile',
        consoleMsg: '[PROFILE] Save Profile Failure ===> ',
        data: err,
      });
    }
    */
  }),

  weightGoalOptions: [
    {
      label: "Lose",
      value: "Lose"
    },
    {
      label: "Gain",
      value: "Gain"
    },
    {
      label: "Maintain",
      value: "Maintain"
    }
  ],

  dietaryPreferencesAndRestrictionOptions: [
    {
      label: "Dairy-free",
      description: null
    },
    {
      label: "Gluten-free",
      description: null
    },
    {
      label: "Vegan",
      description: null
    },
    {
      label: "Vegetarian",
      description: null
    }
  ],

  medicalConditionOptions: [
    {
      label: "Cardiovascular Disease",
      description: null
    },
    {
      label: "Cholesterol",
      description: null
    },
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Hypertension",
      description: null
    }
  ],

  foodAllergyOptions: [
    {
      label: "Diabetes",
      description: null
    },
    {
      label: "Cardiovascular Disease",
      description: null
    }
  ],

  mealsPerDayOptions: [
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    },
    ,
    {
      label: "4",
      value: "4"
    },
    ,
    {
      label: "5",
      value: "5"
    }
  ],

  mealTimingOptions: [
    {
      label: "Intermittent Fasting",
      description: null
    },
    {
      label: "Multiple Small Meals",
      description: null
    }
  ],

  cookingSkillOptions: [
    {
      label: "Beginner",
      value: "Beginner"
    },
    {
      label: "Intermediate",
      value: "Intermediate"
    },
    {
      label: "Advanced",
      value: "Advanced"
    }
  ],

};

export default event;