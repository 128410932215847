const Badge = ({
  children
}) => {
  return (
    <span className="inline-flex items-center rounded-md bg-gray-100 dark:bg-slate-800 px-2 py-1 text-xs font-medium text-gray-600 dark:text-slate-400 ring-1 ring-inset ring-gray-500/10">
      { children }
    </span>
  );
}
 
export default Badge;