import { useController } from 'react-hook-form';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TextArea = ({
  control, // required
  name, // required
  placeholder = "",
  helpText = "",
  rows = 3,
  disabled = false,
  required = false,
  isErrored = false,
  errorMessage = "Not a valid value",
  onInput,
  autoFocus,
  className,
  border = true
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: { value: required, message: 'This is a required field' },
    },
  });

  return (
    <>
      <div>
        <textarea
          name={field.name}
          control={control}
          onChange={field.onChange} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          value={field.value ?? ''}
          rows={rows}
          placeholder={placeholder}
          className={classNames(
            isErrored ? 'text-red-500 ring-red-500 placeholder:text-red-t00 focus:ring-red-500' : 'text-gray-800 dark:text-slate-300 placeholder:text-gray-400',
            border ? 'py-1.5 rounded-md shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset disabled:ring-gray-200 ring-gray-300 dark:ring-slate-700 focus:ring-green-600' : '',
            'block w-full border-0 focus:ring-transparent bg-white dark:bg-slate-800 sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500',
            className
          )}
          disabled={disabled}
          required={required}
          aria-invalid={isErrored}
          onInput={onInput}
          aria-describedby={name + "-error"}
          autoFocus={autoFocus}
        />
        {/*
        <div className={isErrored ? "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" : "hidden"}>
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
        */}
      </div>
      <p className={helpText ? "mt-2 text-sm text-gray-500 dark:gray-400" : "hidden"} id={name + "-help-text"}>
        {helpText}
      </p>
      <p className={isErrored ? "mt-2 text-sm text-red-600" : "hidden"} id={name + "-error"}>
        {errorMessage}
      </p>
    </>
  )
}

export default TextArea;