import { useState } from 'react';

import Button from "../Form/Button/Button";
import Modal from '../Modal/Modal';

import {
  RocketLaunchIcon
} from '@heroicons/react/24/outline';
import Premium from '../../../pages/Premium/Premium';

const Card = ({
  header, 
  children, 
  footer, 
  showPremiumLock=false
}) => {

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);

  const onPrmeiumClose = () => {
    setIsPremiumModalOpen(false);
  }

  return (    
    <> 
      <div className="relative overflow-hidden rounded-lg shadow bg-white dark:bg-slate-900">
        <div className={header ? "px-5 py-3 font-semibold text-sm bg-gray-50 dark:bg-slate-800 text-gray-900 dark:text-white" : "display:none"}>
          { header }
        </div>
        <div className="px-4 py-5 sm:p-6 text-sm text-gray-600 dark:text-slate-400">
          { children }
        </div>
        <div className={footer ? "px-4 py-2 sm:px-6 text-gray-800 dark:text-white border-t dark:border-slate-800" : "display:none"}>
          { footer }
        </div>
        <div className={showPremiumLock ? "absolute top-[44px] z-101 h-[calc(100%-44px)] w-full backdrop-blur-sm bg-white/50 dark:bg-slate-900/60 flex" : "hidden"}>
          <div className="m-auto">
            <Button
              accent={true}
              onClick={() => setIsPremiumModalOpen(true)}>
              <div className="flex gap-2">
                <span>Go Premium</span>
                <RocketLaunchIcon className="h-5 w-5 mr-auto" aria-hidden="true" />
              </div>
            </Button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isPremiumModalOpen}
        onClose={onPrmeiumClose}>
          
        <Premium />

      </Modal>
    </>
  );
}

export default Card;