const Button = ({
  type = "button",
  onClick,
  disabled = false,
  label,
  dataDropdownToggle = "none",
  primary = false,
  accent = false,
  warn = false,
  border = false,
  boldFont = true,
  className = '',
  children
}) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <button
      type={ type }
      onClick={ onClick }
      disabled={ disabled }
      data-dropdown-toggle={ dataDropdownToggle }
      className={classNames(
        primary ? 'text-white bg-green-600 hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-800 focus-visible:outline-green-600' :
        accent ? 'text-white bg-indigo-500 hover:bg-indigo-600 focus-visible:outline-indigo-600' :
        warn ? 'text-white bg-rose-500 hover:bg-rose-600 dark:bg-rose-700 dark:hover:bg-rose-800 focus-visible:outline-rose-600' : 
              'bg-gray-100 dark:bg-slate-800 text-gray-900 dark:text-slate-300 hover:bg-gray-50 dark:hover:bg-slate-800',
        "rounded-md px-3 py-2 text-sm disabled:cursor-not-allowed disabled:bg-gray-200 dark:disabled:bg-slate-700 disabled:text-gray-400 dark:disabled:text-slate-500",
        border ? 'shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 disabled:ring-0' : '',
        boldFont ? 'font-semibold' : '',
        className
      )}
    >
      { children }
    </button>
  )
}

export default Button;