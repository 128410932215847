const WellnessFormGoalsMuscleEnd = ({ control }) => {
  return (
    <section>

      <div className="text-xl font-bold leading-6 text-gray-900 dark:text-white pt-6 pb-3 px-10">
        Great, we can help you get the look you want.
      </div>

      <div className="text-sm font-light leading-6 text-gray-900 dark:text-white px-10">
        <p>
          We recommend you try some of our bodyweight and low-weight HIIT workout routines. 
          We also recommend tracking your macronutrients to make sure you're getting enough protein.
        </p>
        <p className="pt-3 dark:text-white">
          Let's get into the specifics so we can build your personalized plan.
        </p>
      </div>

    </section>
  );
}
 
export default WellnessFormGoalsMuscleEnd;