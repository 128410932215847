import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

const ProfileSearch = () => { 
  const [query, setQuery] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);

  const people = [
    {
      id: 1,
      name: 'Leslie Alexander',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More users...
  ]

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        });

  return (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={(person) => {
        setQuery('')
        setSelectedPerson(person)
      }}
    >
      <div className="relative">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white dark:bg-slate-800 py-3 pl-5 pr-12 text-gray-800 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-700 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          placeholder='Search HelloLife...'
          onBlur={() => setQuery('')}
          displayValue={(person) => person?.name}
        />

        {filteredPeople.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white dark:bg-slate-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <ComboboxOption
                key={person.id}
                value={person}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-800 data-[focus]:bg-green-500 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <img src={person.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                  <span className="ml-3 truncate group-data-[selected]:font-semibold">{person.name}</span>
                </div>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-500 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}

export default ProfileSearch;