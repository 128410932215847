import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import accountImagePlaceholder from '../../assets/images/user_image_placeholder.jpg';

import Card from '../../components/ui/Card/Card';
import Modal from '../../components/ui/Modal/Modal';
import Button from '../../components/ui/Form/Button/Button';
import TextArea from '../../components/ui/Form/TextArea/TextArea';

const Post = ({
  onComplete
}) => {
  const account = useStoreState((state) => state.account.data);
  const myProfile = useStoreState((state) => state.profile.myProfile);
  const savePost = useStoreActions((actions) => actions.post.savePost);

  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [post, setPost] = useState({
    uid: null,
    accountUid: account.uid,
    createdAt: null,
    post: null
  });

  const onCreatePostClick = () => {
    setIsCreatePostModalOpen(true);
  }

  const onCreatePostModalClose = () => {
    reset();
    setIsCreatePostModalOpen(false);
  }

  const { 
    reset, 
    formState, 
    handleSubmit, 
    control, 
    setError, 
    formState: { errors, isValid, isDirty, isSubmitting } 
  } = useForm({
    defaultValues: {
      uid: null,
      accountUid: account.uid,
      createdAt: null,
      post: null
    },
    values: {
      ...post
    }
  });

  const handleComplete = () => {
    handleSubmit(onSubmit, onError)();
    reset();
    onComplete(true);
    setIsCreatePostModalOpen(false);
  }

  const onSubmit = async (post, e) => {  
    await savePost({
      ...post,
    });
  }

  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/hello/' + myProfile?.username);
  }

  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <Card>
        <div className="flex items-start space-x-4 hover:cursor-pointer">
          <div className="flex-shrink-0" onClick={() => goToProfile()}>
            <img
              alt=""
              src={ account?.photoURL ? account.photoURL : accountImagePlaceholder }
              className="inline-block h-10 w-10 rounded-full"
            />
          </div>
          <div className="min-w-0 flex-1">
            <button
              className="w-full px-4 py-2 rounded-lg text-left text-sm md:text-lg font-normal bg-gray-200/60 dark:bg-slate-800 hover:bg-gray-200 dark:hover:bg-slate-700/60 text-gray-500 dark:slate-700"
              onClick={() => onCreatePostClick()}>
              What's on your mind, {account.firstName}?
            </button>
          </div>
        </div>
      </Card>

      <Modal 
        title="Create Post"
        isOpen={isCreatePostModalOpen}
        onClose={onCreatePostModalClose}>

        <div className="p-5">

          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0">
              <img
                alt=""
                src={ account?.photoURL ? account.photoURL : accountImagePlaceholder }
                className="inline-block h-10 w-10 rounded-full"
              />
            </div>
            <div className="font-semibold text-black dark:text-white">
              { account.firstName } { account.lastName }
            </div>
          </div>

          <form>
            <TextArea
              name="post"
              className="mt-5 text-2xl p-0 placeholder:text-gray-500 text-black dark:text-white dark:bg-slate-900"
              border={false}
              placeholder={'What\'s on your mind, ' + account.firstName + '?'}
              control={control}
              autoFocus
              required={true} />
            
            <div className="mt-5">
              <Button 
                className="w-full"
                primary={true}
                disabled={!formState.isValid}
                onClick={() => handleComplete()}>
                Post
              </Button>
            </div>
          </form>

        </div>

      </Modal>
    </>
  )
}

export default Post;