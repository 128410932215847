
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Sidebar = ({
  className,
  children
}) => { 
  return (
    <div className={classNames(
      className ? className : '',
      "h-screen bg-gray-100 dark:bg-slate-950 border-l border-gray-200 dark:border-slate-800"
      )}>
      { children }
    </div>
  )
}

export default Sidebar;